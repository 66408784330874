.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-home {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(.5px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-container {
  display: flex;
  height: 100vh; /* This ensures the container takes up the full viewport height */
}

.App-content {
  flex: 1; /* This ensures the content takes up the remaining space after the sidebar */
}

.MenuItem a {
  text-decoration: none; /* Removes the underline */
  color: inherit; /* Makes the link color same as the surrounding text */
  display: block; /* Makes the entire area clickable */
  padding: 5px 10px; /* Optional: Adds some padding */
}

.MenuItem a:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle background on hover */
}

body {
  margin: 0;
  font-family: "Figtree", -apple-system, BlinkMacSystemFont, "Segoe UI", "Figtree", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background: #ffffff;
  font-size: 16px;
}
.app {
  background: #e0ecf0;
}
h1 {
  color: #165a72;
  margin: 10px auto;
  font-size: 40px;
}
.h1-home {
  color: #ffffff;
  margin: 50px auto;
  font-size: calc(10px + 2vmin);
}
.menu1 {
  margin-bottom: 40px;
  margin-top: 20px;
}
h2 {
  color: #165a72;
}

/* MyComponent.css */
.separator {
  border-top: 2px solid #000; /* You can change the color and thickness as needed */
  margin: 10px 0; /* Adjust margin to control the space above and below the separator */
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

a {
  color: #2851A3; /* Instagram color or any color that fits your design */
  text-decoration: none;
  align-items: center; /* Ensures icon and text are aligned */
}

a:hover {
  text-decoration: underline;
}

InstagramIcon {
  font-size: 24px; /* Adjust size as needed */
}
